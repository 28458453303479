.cstm-bg {
    /* background: url(http://localhost:3000/assets/img/welcome_background.gif); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* color: #e64e83; */
    /* height: 120px; */
    
}

.cstm-bg-dark {
    /* background: #bdc3c7;
    background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);  
    background: linear-gradient(to right, #2c3e50, #bdc3c7);  */
    border-radius: 5px;
    display: flex;
    /* height: 160px; */
    /* align-items:center; */
}
