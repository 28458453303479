.docs-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.docs-h1 {
    color: #343a40;
    font-size: 2.5em;
    margin-bottom: 10px;
}
.docs-h2 {
    color: #495057;
    font-size: 1.8em;
    margin-top: 30px;
    border-bottom: 2px solid #dee2e6;
    padding-bottom: 5px;
}
.docs-h4 {
    color: #495057;
    font-size: 1.2em;
    margin-top: 30px;
    border-bottom: 2px solid #dee2e6;
    padding-bottom: 5px;
}
.docs-p {
    font-size: 1em;
    line-height: 1.7;
    margin-bottom: 20px;
}
.docs-code {
    background-color: #f1f3f5;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.95em;
}
.docs-pre {
    background-color: #f8f9fa;
    border-left: 5px solid #007BFF;
    padding: 15px;
    overflow-x: auto;
    margin-bottom: 20px;
    font-size: 0.95em;
    border-radius: 4px;
}
.docs-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    border-radius: 8px;
    overflow: hidden;
}
.docs-th, .docs-td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e9ecef;
}
.docs-th {
    background-color: #007BFF;
    color: #ffffff;
    font-weight: 600;
}
.docs-example {
    background-color: #e2e6ea;
    padding: 15px;
    border-radius: 4px;
    border-left: 5px solid #343a40;
    margin-bottom: 30px;
    font-size: 0.95em;
}
.docs-example .docs-code {
    background-color: transparent;
    padding: 0;
}
.docs-footer {
    text-align: center;
    padding: 20px;
    font-size: 0.85em;
    color: #868e96;
    border-top: 1px solid #dee2e6;
    margin-top: 40px;
}